<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row justify-center">
        <q-icon size="48px" name="check_circle" color="positive" />
      </div>
      <div class="row justify-center">
        <div class="text-h5">The Upload Has Completed</div>
      </div>
      <div class="row justify-center q-my-md">
        {{ users.length - unProcessedUsers.length }} users were successfully created
      </div>
      <div v-if="unProcessedUsers.length > 0" class="row justify-center q-my-xl">
        <div v-if="unProcessedUsers.length === 1" class="col-12 text-h6 text-center">1 user was not created during the import</div>
        <div v-else class="col-12 text-h6 text-center">{{ unProcessedUsers.length }} users were not created during the import</div>
        <q-btn class="col-4 q-my-md" color="primary" @click="downloadUnprocessedUsers">Download Failed Users</q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
  components: { },
  props: ['users', 'unProcessedUsers'],
  data () {
    return {
      xlsxObject: []
    }
  },
  methods: {
    completeTab () {
      this.$emit('update:users', this.newUsers)
      this.$emit('nextTab')
    },
    downloadUnprocessedUsers () {
      this.unProcessedUsers.forEach(user => {
        let strippedUser = {}
        strippedUser.first_name = user.first_name
        strippedUser.last_name = user.last_name
        strippedUser.email = user.email
        strippedUser.is_advocate = user.is_advocate
        strippedUser.is_exempt = user.is_exempt
        strippedUser.password = user.password
        this.xlsxObject.push(strippedUser)
      })

      let fileName = 'failed_imported_users.xlsx'
      let workSheetName = 'Sheet 1'

      let wb = XLSX.utils.book_new()
      let ws = XLSX.utils.json_to_sheet(this.xlsxObject)
      XLSX.utils.book_append_sheet(wb, ws, workSheetName)
      XLSX.writeFile(wb, fileName)
    }
  }
}
</script>
