<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row full-width justify-center">
        <file-upload
          class="btn btn-primary file-upload-container cursor-pointer col-12"
          :drop="true"
          :drop-directory="false"
          v-model="files"
          :extensions="/\.(csv|xls|xlsx)$/i"
          ref="upload">
          <q-icon name="cloud_upload" class="primaryText-text items-center cursor-pointer" size="18rem"></q-icon>
          <div class="text-h6">Drop Your File to Upload</div>
        </file-upload>
        <q-file style="display: none;" v-model="file" ref="fileInput" accept=".csv, .xls, .xlsx" />
        <q-btn color="primary" class="q-my-xl" @click="$refs.fileInput.$el.click()">Choose File</q-btn>
      </div>
    </div>

    <q-dialog v-model="showValidatingModal" persistent>
      <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
        <q-card-section>
          <div class="text-h5">Validating Uploaded Data</div>
        </q-card-section>
        <q-card-section>
          <q-linear-progress rounded size="20px" :value="validationProgress" color="primary" class="q-mt-sm" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showExistingUsersModal" persistent>
      <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
        <q-card-section>
          <div class="text-h5">The Following Account Emails Already Exist</div>
          <div class="text-subtitle2">These users will not be included in the import</div>
        </q-card-section>
        <q-card-section>
          <q-list>
            <q-item v-for="user in existingUsers" :key="user.email">
              <q-item-section>
                <q-item-label>{{ user.email }}</q-item-label>
                <q-item-label caption class="primaryText-text">{{ user.first_name }} {{ user.last_name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" @click="cancelImport" v-close-popup />
          <q-btn label="Continue Without These Users" color="primary" @click="completeTab" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showMaxLimitModal" persistent>
      <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
        <q-card-section>
          <div class="text-h5">Max Limit Reached</div>
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">The file you uploaded exceeds our max limit of 2000 users per upload. Please break up your file into smaller batches before uploading</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn label="Okay" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import XLSX from 'xlsx'
import FileUpload from 'vue-upload-component'
export default {
  components: { FileUpload },
  props: ['users'],
  data () {
    return {
      files: [],
      file: null,
      workBook: null,
      workBookUsers: [],
      showValidatingModal: false,
      validationProgress: 0,
      newUsers: [],
      existingUsers: [],
      showExistingUsersModal: false,
      showMaxLimitModal: false
    }
  },
  watch: {
    files: function (newVal) {
      if (newVal) {
        this.file = newVal[0].file
      }
    },
    file: function (newVal) {
      if (newVal) {
        this.getUsersFromFile()
      }
    }
  },
  methods: {
    getUsersFromFile () {
      let reader = new FileReader()
      reader.onload = async e => {
        let data = new Uint8Array(e.target.result)
        this.workBook = XLSX.read(data, { type: 'array' })
        this.workBookUsers = XLSX.utils.sheet_to_json(this.workBook.Sheets[this.workBook.SheetNames[0]])
        if (this.workBookUsers.length > 2000) {
          this.showMaxLimitModal = true
          return
        }
        this.showValidatingModal = true
        // check if email already exists
        for (let i = 0; i < this.workBookUsers.length; i++) {
          let user = this.workBookUsers[i]
          this.validationProgress = ((i + 1) / this.workBookUsers.length)
          let userExists = await this.$axios.post(this.$consts.CHECK_USER_EXISTS_URL, { email: user.email })
          if (userExists.message === 'true') {
            this.existingUsers.push(user)
          } else {
            Vue.set(user, 'location', null)
            Vue.set(user, 'departments', [])
            Vue.set(user, 'roles', [])

            if (typeof user.is_advocate === 'string' && user.is_advocate.toLowerCase() === 'yes') {
              user.is_advocate = true
            } else if (!user.is_advocate || (typeof user.is_advocate === 'string' && user.is_advocate.toLowerCase() === 'no')) {
              user.is_advocate = false
            }

            if (typeof user.is_exempt === 'string' && user.is_exempt.toLowerCase() === 'yes') {
              user.is_exempt = true
            } else if (!user.is_exempt || (typeof user.is_exempt === 'string' && user.is_exempt.toLowerCase() === 'no')) {
              user.is_exempt = false
            }

            this.newUsers.push(user)
          }
        }
        this.showValidatingModal = false

        if (this.existingUsers.length > 0) {
          // show modal
          this.showExistingUsersModal = true
        } else {
          this.completeTab()
        }
      }
      reader.readAsArrayBuffer(this.file)
    },
    cancelImport () {
      this.file = null
      this.existingUsers = []
      this.newUsers = []
      this.workBookUsers = []
    },
    completeTab () {
      this.$emit('update:users', this.newUsers)
      this.$emit('nextTab')
    }
  }
}
</script>
