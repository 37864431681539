<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-md full-width">
      <div v-if="!allUsersValid" class="row justify-center q-mb-xs">
        <div class="text-subtitle2">All users are required to have a first name, last name, email address, password, location, and a role</div>
      </div>
      <div class="row q-mb-xl justify-center">
        <q-btn class="col-4" :disable="!allUsersValid" color="primary" @click="validateUsers">Complete Import for {{ users.length }} Users</q-btn>
      </div>

      <q-dialog v-model="confirmUploadModal" persistent>
        <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
          <q-card-section>
            <div class="text-h5">Confirm Upload</div>
          </q-card-section>
          <q-card-section>
            <div>You are about to start the import process for {{ users.length }} users.</div>
            <div class="q-my-lg">Estimated upload time: {{ estimatedRemaingUploadTime }}</div>
            <div class="q-my-lg">Please do not close you browser while this process is running or you might only create a subset of your users.</div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary" v-close-popup />
            <q-btn label="Start Upload" color="primary" @click="createUsers" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <div class="row">
        <div class="row full-width">
          <q-table :data="users" :columns="columns" row-key="email" selection="multiple" :selected.sync="selected" :filter="filter" :pagination.sync="pagination" grid class="full-width">
            <template v-slot:top-left>
              <div class="row q-gutter-md">
                <q-checkbox v-model="selectAll" label="Select All" color="primary" keep-color />
                <q-btn v-if="showClearAll" color="primary" icon="clear" size="md" @click="selected = []" flat>Clear Selection</q-btn>
                <q-btn v-if="showClearAll" outline color="primary" :disable="selected.length === 0" @click="showMassAssign('location')">Mass Assign Location</q-btn>
                <q-btn v-if="showClearAll" outline color="primary" :disable="selected.length === 0" @click="showMassAssign('departments')">Mass Assign User Groups</q-btn>
                <q-btn v-if="showClearAll" outline color="primary" :disable="selected.length === 0" @click="showMassAssign('roles')">Mass Assign Roles</q-btn>
              </div>
              <div class="row">
                <div class="text-caption text-italic">Click a card to select it</div>
              </div>
            </template>
            <template v-slot:top-right>
              <q-input debounce="300" v-model="filter" placeholder="Search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:item="props">
              <div
                class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3 grid-style-transition"
                :style="props.selected ? 'transform: scale(0.95);' : ''"
              >
                <q-card :style="props.selected ? `border-color: ${borderColor}` : ``" :bordered="props.selected" class="bg-lmsBackground primaryText-text">
                  <q-card-section class="row full-width justify-center cursor-pointer" @click="props.selected = !props.selected">
                    <div class="col-12 row justify-center">
                      <q-avatar size="40px">
                        <q-img :src="$consts.DEFAULT_AVATAR" style="height: 40px; width: 40px; border-radius: 25px;"/>
                      </q-avatar>
                    </div>
                    <div class="col-12 row justify-center">
                      <div class="text-h6">{{props.row.first_name}} {{props.row.last_name}}</div>
                    </div>
                  </q-card-section>
                  <q-separator />
                  <q-card-section>
                    <div class="row justify-between">
                      <q-input class="col-5 q-my-xs" filled dense v-model="props.row.first_name" label="First Name" />
                      <q-input class="col-5 q-my-xs" filled dense v-model="props.row.last_name" label="Last Name" />
                    </div>
                    <q-input class="q-my-xs" filled dense v-model="props.row.email" label="Email" />
                    <div class="row justify-between">
                      <q-checkbox class="q-my-xs" v-model="props.row.is_advocate" label="Advocate" color="primary" keep-color />
                      <q-checkbox class="q-my-xs" v-model="props.row.is_exempt" label="Unrestricted Access" color="primary" keep-color />
                    </div>
                    <q-input class="q-my-xs" filled dense v-model="props.row.password" label="Password" />
                    <q-select class="q-my-xs" filled dense v-model="props.row.location" :options="$store.getters['locations/list']" emit-value map-options label="Location" popup-content-class="bg-lmsBackground primaryText-text"></q-select>
                    <q-select class="q-my-xs" filled dense v-model="props.row.departments" :options="$store.getters['departments/list']" emit-value map-options multiple label="User Groups" popup-content-class="bg-lmsBackground primaryText-text">
                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label v-html="scope.opt.label" ></q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-checkbox v-model="props.row.departments" :val="scope.opt.value" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                    <q-select class="q-my-xs" filled dense v-model="props.row.roles" :options="$store.getters['auth/rolesList']" emit-value map-options multiple label="Roles" popup-content-class="bg-lmsBackground primaryText-text">
                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label v-html="scope.opt.label" ></q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-checkbox v-model="props.row.roles" :val="scope.opt.value" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-card-section>
                </q-card>
              </div>
            </template>
          </q-table>
        </div>
        <div class="row full-width q-mt-xl justify-center">
          <q-btn class="col-4" :disable="!allUsersValid" color="primary" @click="validateUsers">Complete Import for {{ users.length }} Users</q-btn>
        </div>
      </div>

      <q-dialog v-model="showMassAssignModal" persistent>
        <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
          <q-card-section class="row items-center justify-between">
            <div class="text-h5">Assign {{ massAssignTypeLabel }} to {{ selected.length }} Users</div>
            <q-btn dense flat icon="close" style="z-index: 2" v-close-popup>
              <q-tooltip>Close</q-tooltip>
            </q-btn>
          </q-card-section>
          <q-card-section>
            <q-select emit-value map-options filled :multiple="massAssignType !== 'location'" :options="massAssignOptions" v-model="massAssignSelection" :label="massAssignTypeLabel" popup-content-class="bg-lmsBackground primaryText-text"  autocomplete="no">
              <template v-slot:option="scope" v-if="massAssignType !== 'location'">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <q-item-section>
                    <q-item-label v-html="scope.opt.label" ></q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-checkbox v-model="massAssignSelection" :val="scope.opt.value" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-card-section>
          <q-card-section class="row justify-center">
            <q-btn color="primary" @click="massAssign">Assign to Selected Users</q-btn>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="showUploadingModal" persistent>
        <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
          <q-card-section>
            <div class="text-h5">Uploading All Users</div>
          </q-card-section>
          <q-card-section>
            {{ estimatedRemaingUploadTime }} remaining
          </q-card-section>
          <q-card-section>
            <q-linear-progress rounded size="20px" :value="uploadProgress" color="primary" class="q-mt-sm" />
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="showBadEmailsModal" persistent @hide="badEmailUsers = []">
        <q-card style="min-width: 568px" class="bg-lmsBackground primaryText-text">
          <q-card-section>
            <div class="text-h5">The Following Users Do Not Have Valid Emails</div>
            <div class="text-subtitle2">Please Make Corrections Before Moving Forward</div>
          </q-card-section>
          <q-card-section>
            <q-list>
              <q-item v-for="user in badEmailUsers" :key="user.email">
                <q-item-section>
                  <q-item-label>{{ user.email }}</q-item-label>
                  <q-item-label caption class="primaryText-text">{{ user.first_name }} {{ user.last_name }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-card-actions align="center">
            <q-btn color="primary" v-close-popup>Ok Got It</q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import { colors } from 'quasar'
export default {
  props: ['users'],
  components: { },
  data () {
    return {
      pagination: {
        rowsPerPage: 25
      },
      showMassAssignModal: false,
      massAssignType: null,
      massAssignTypeLabel: null,
      massAssignSelection: null,
      massAssignOptions: [],
      selectAll: false,
      showClearAll: false,
      filter: '',
      selected: [],
      showUploadingModal: false,
      confirmUploadModal: false,
      unProcessedUsers: [],
      badEmailUsers: [],
      showBadEmailsModal: false,
      numberOfProcessedUsers: 0,
      uploadProgress: 0,
      columns: [
        { name: 'first_name', label: 'first_name', field: 'first_name', align: 'left' },
        { name: 'last_name', label: 'last_name', field: 'last_name', align: 'left' },
        { name: 'email', label: 'email', field: 'email', align: 'left' },
        { name: 'is_advocate', label: 'is_advocate', field: 'is_advocate', align: 'left' },
        { name: 'is_exempt', label: 'is_exempt', field: 'is_exempt', align: 'left' },
        { name: 'password', label: 'password', field: 'password', align: 'left' },
        { name: 'location', label: 'location', field: 'location', align: 'left' }
      ]
    }
  },
  watch: {
    selected: function (newVal) {
      if (newVal.length === this.users.length) {
        this.selectAll = true
        this.showClearAll = true
      } else if (newVal.length > 0) {
        this.showClearAll = true
      } else if (newVal.length === 0) {
        this.selectAll = false
        this.showClearAll = false
      }
    },
    selectAll: function (newVal) {
      if (newVal) {
        this.selected = [ ...this.users ]
      } else if (!newVal) {
        this.selected = []
      }
    }
  },
  computed: {
    allUsersValid () {
      return this.users.every(user => {
        return user.first_name && user.last_name && user.email && user.password && user.location && user.roles.length > 0
      })
    },
    estimatedRemaingUploadTime () {
      let timeInSeconds = (this.users.length - this.numberOfProcessedUsers) // about 1 second average per user

      if (timeInSeconds <= 60) {
        return 'Less Than A Minute'
      } else {
        return `${Math.ceil(timeInSeconds / 60)} minutes`
      }
    },
    borderColor () {
      return colors.getBrand('primary')
    }
  },
  methods: {
    showMassAssign (type) {
      this.showMassAssignModal = true

      switch (type) {
        case 'location':
          this.massAssignType = 'location'
          this.massAssignTypeLabel = 'Location'
          this.massAssignOptions = this.$store.getters['locations/list']
          break
        case 'departments':
          this.massAssignSelection = []
          this.massAssignType = 'departments'
          this.massAssignTypeLabel = 'User Groups'
          this.massAssignOptions = this.$store.getters['departments/list']
          break
        case 'roles':
          this.massAssignSelection = []
          this.massAssignType = 'roles'
          this.massAssignTypeLabel = 'Roles'
          this.massAssignOptions = this.$store.getters['auth/rolesList']
          break
        default:
          break
      }
    },
    massAssign () {
      this.selected.forEach(user => {
        user[this.massAssignType] = this.massAssignSelection
      })
      this.massAssignSelection = null
      this.massAssignType = null
      this.massAssignTypeLabel = null
      this.massAssignOptions = []
      this.showMassAssignModal = false
    },
    async createUsers () {
      this.showUploadingModal = true
      for (let i = 0; i < this.users.length; i++) {
        let user = this.users[i]
        user.is_activated = true
        user.is_fulltime = true
        user.started_at = null
        user.password_confirmation = user.password // probably not ideal but doing it for now
        user.username = user.email
        user.customer_uid = user.location

        try {
          await this.$store.dispatch('auth/addUser', user)
          this.uploadProgress = ((i + 1) / this.users.length)
          this.numberOfProcessedUsers = (i + 1)
          if ((i + 1) === this.users.length) {
            this.showUploadingModal = false
            this.$emit('update:unProcessedUsers', this.unProcessedUsers)
            this.$emit('nextTab')
          }
        } catch (e) {
          this.unProcessedUsers.push(user)
          this.uploadProgress = ((i + 1) / this.users.length)
          if ((i + 1) === this.users.length) {
            this.showUploadingModal = false
            this.$emit('update:unProcessedUsers', this.unProcessedUsers)
            this.$emit('nextTab')
          }
        }
      }
    },
    validateUsers () {
      let isValid = true
      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.users.forEach(user => {
        if (!emailRegex.test(String(user.email).toLowerCase())) {
          this.badEmailUsers.push(user)
          isValid = false
        }
      })

      if (isValid) {
        this.confirmUploadModal = true
      } else {
        this.showBadEmailsModal = true
      }
    }
  }
}
</script>

<style lang="stylus">
.grid-style-transition
  transition: transform .28s, background-color .28s
</style>
