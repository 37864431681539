<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between">
        <div class="text-h4 col-md-9">Import Users</div>
      </div>

      <div class="row full-width">
        <q-stepper v-model="step" ref="importUserStepper" animated flat class="bg-lmsBackground full-width">
          <q-step :name="1" title="Upload" icon="cloud_upload">
            <div class="row justify-between">
              <q-list dense>
                <div class="text-h6">How To Import</div>
                <q-item>1. Download Template File</q-item>
                <q-item>2. Fill Out Template File (Max: 2000 users)</q-item>
                <q-item>3. Upload filled out file and follow the wizard</q-item>
                <div class="text-body2">We recommend doing an import for each location you have. By importing only users at one specific location, it will help speed up the process when assigning users to a location.</div>
              </q-list>
              <div>
                <q-btn size="md" class="col-4" color="primary" @click="downloadTemplate">Download Template</q-btn>
              </div>
            </div>
            <upload-users-tab :users.sync="users" @nextTab="nextTab"></upload-users-tab>
          </q-step>
          <q-step :name="2" title="Edit" icon="edit">
            <edit-users-tab :users.sync="users" @nextTab="nextTab" :unProcessedUsers.sync="unProcessedUsers"></edit-users-tab>
          </q-step>
          <q-step :name="3" title="Complete" icon="check_circle">
            <complete-users-tab :users.sync="users" :unProcessedUsers.sync="unProcessedUsers"></complete-users-tab>
          </q-step>
        </q-stepper>
      </div>
    </div>
  </div>
</template>

<script>
import uploadUsersTab from 'components/importUsers/uploadUsersTab'
import editUsersTab from 'components/importUsers/editUsersTab'
import completeUsersTab from 'components/importUsers/completeUsersTab'
import XLSX from 'xlsx'
export default {
  components: { uploadUsersTab, editUsersTab, completeUsersTab },
  data () {
    return {
      step: 1,
      users: [],
      unProcessedUsers: [],
      uploadProgress: 0,
      template: [
        { first_name: 'John', last_name: 'Doe', email: 'johndoe@midstates.com', is_advocate: 'FALSE', is_exempt: 'FALSE', password: 'password123' }
      ]
    }
  },
  methods: {
    nextTab () {
      this.step++
    },
    downloadTemplate () {
      let fileName = 'import_user_template.xlsx'
      let workSheetName = 'Sheet 1'

      let wb = XLSX.utils.book_new()
      let ws = XLSX.utils.json_to_sheet(this.template)
      XLSX.utils.book_append_sheet(wb, ws, workSheetName)
      XLSX.writeFile(wb, fileName)
    }
  }
}
</script>
